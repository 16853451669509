<i18n src="@/locales/sofia_dental_center.json" />
<i18n src="@/locales/articles-card-content.json" />
<i18n src="@/locales/card-home-content-clinic.json" />

<template>
  <div class="clinic-sofia">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <ImanH3>{{ $t('h3_title_1') }}</ImanH3>
        <p>
          {{
            $t('article_p1')
          }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_1')">
          {{ $t('more_info_1') }}
        </ImanMoreInfo>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section class="section">
      <div class="columns is-centered">
        <div class="column is-half">
          <ImanVideo :video="video" />
        </div>
      </div>
    </section>
    <section class="section">
      <ImanArticle>
        <div v-html="$t('article_p2')" />
      </ImanArticle>
    </section>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[1]">
        <ImanH3>{{ $t('h3_title_2') }}</ImanH3>
        <p>
          {{
            $t('article_p3')
          }}
        </p>
        <p>
          {{
            $t('article_p4')
          }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_2')">
          {{ $t('more_info_2') }}
        </ImanMoreInfo>
      </ImanHomeSection>
      <ImanHomeSection :img="img[2]">
        <ImanH3>{{ $t('h3_title_3') }}</ImanH3>
        <p>
          {{
            $t('article_p5')
          }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[3]">
        <ImanH3>{{ $t('h3_title_4') }}</ImanH3>
        <p>
          {{
            $t('article_p6')
          }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_3')">
          {{ $t('more_info_3') }}
        </ImanMoreInfo>
      </ImanHomeSection>
      <ImanHomeSection :img="img[4]">
        <ImanH3>{{ $t('h3_title_5') }}</ImanH3>
        <p>
          {{
            $t('article_p7')
          }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[5]">
        <ImanH3>{{ $t('h3_title_6') }}</ImanH3>
        <p>
          {{
            $t('article_p8')
          }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_4')">
          {{ $t('more_info_4') }}
        </ImanMoreInfo>
      </ImanHomeSection>
      <ImanHomeSection :img="img[6]">
        <ImanH3>{{ $t('h3_title_7') }}</ImanH3>
        <p>
          {{
            $t('article_p9')
          }}
        </p>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[7]">
        <p>
          {{
            $t('article_p10')
          }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_5')">
          {{ $t('more_info_5') }}
        </ImanMoreInfo>
      </ImanHomeSection>
      <ImanHomeSection :img="img[8]">
        <ImanH3>{{ $t('h3_title_8') }}</ImanH3>
        <p>
          {{
            $t('article_p11')
          }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_6')">
          {{ $t('more_info_6') }}
        </ImanMoreInfo>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_3') }}</ImanH2>
    <section class="columns is-centered background-section">
      <div class="column is-8">
        <ImanCollapsableCard
          :title="$t('collapse_title_1')"
        >
          <p>
            {{
              $t('collapse_p1')
            }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_2')"
        >
          <div v-html="$t('collapse_p2')" />
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_3')"
        >
          <div v-html="$t('collapse_p3')" />
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_4')"
        >
          <p>
            {{
              $t('collapse_p4')
            }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_5')"
        >
          <div v-html="$t('collapse_p5')" />
        </ImanCollapsableCard>
      </div>
    </section>
    <ImanH2>{{ $t('h2_title_4') }}</ImanH2>
    <section class="section">
      <div class="columns is-centered">
        <div
          v-for="cardContent in cardContentClinic"
          :key="cardContent.id"
          class="column is-2"
        >
          <ImanCountryCard
            :content="cardContent"
            :country-card-class="countryOverlayClass"
          />
        </div>
      </div>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import ImanMoreInfo from "@/components/ImanMoreInfo";
  import ImanArticleSection from "@/components/ImanArticleSection";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import ImanCountryCard from "@/components/ImanCountryCard";
  import {cardHomeContentClinicMixin} from "@/mixins/card-home-content-clinic-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";
  export default {
    name: 'SofiaDentalCenter',
    components: {
      ImanMoreInfo,
      ImanArticleSection,
      ImanCountryCard
    },
    mixins: [
      metaMixin,
      articlesCardContentFilterMixin,
      cardHomeContentClinicMixin
    ],
    data() {
      return {
        articlesCardContentId: [22, 610, 611, 52],
        mainBackgroundClass: 'none-background',
        img: [
          {
            path: 'clinic/swiss/prof-ihde-anveli-dental.jpg',
            position: 'right'
          },
          {
            path: 'clinic/bulgaria/clinique-implants-dentaires-sofia-bulgarie.jpg',
            position: 'right'
          },
          {
            path: 'clinic/bulgaria/clinique-dentaire-bulgarie-sofia-anveli.jpg',
            position: 'right'
          },
          {
            path: 'clinic/bulgaria/orthodontie-etranger.jpg',
            position: 'right'
          },
          {
            path: 'clinic/bulgaria/soins-dentaires-esthetique-sofia-bulgarie.jpg',
            position: 'right'
          },
          {
            path: 'clinic/bulgaria/parc-anveli-dental-sofia.jpg',
            position: 'right'
          },
          {
            path: 'clinic/bulgaria/soins-dentaire-sofia-bulgarie.jpg',
            position: 'right'
          },
          {
            path: 'clinic/bulgaria/implant-basal-bulgarie-sofia.jpg',
            position: 'right'
          },
          {
            path: 'clinic/bulgaria/laboratoire-prothese-dentaire-bulgarie.jpg',
            position: 'right'
          }
        ],
        video: {
          src: 'https://www.youtube.com/embed/wP5xCC42DMg'
        },
        countryOverlayClass: {
          overlay:'overlay show-overlay',
          element: 'element show-element'
        },
      }
    },
    computed: {
      cardContentClinic () {
        return this.cardHomeContentClinic.filter((cardClinic) => cardClinic.id !== 3)
      },
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../assets/img/thumbnail/MINIATURE_4.2.png')
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
          this.countryOverlayClass = {
            overlay:'overlay',
            element: 'element'
          }
        }
        if (document.body.scrollTop > 970 || document.documentElement.scrollTop > 970) {
          this.mainBackgroundClass = 'fixed-background'
          this.countryOverlayClass = {
            overlay:'overlay',
            element: 'element'
          }
        }
        if (document.body.scrollTop > 2800 || document.documentElement.scrollTop > 2800) {
          this.countryOverlayClass = {
            overlay:'overlay show-overlay',
            element: 'element show-element'
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../../assets/img/clinic/bulgaria/H1-clinique-dentaire-sofia-bulgarie-d.jpg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../../assets/img/clinic/bulgaria/H1-clinique-dentaire-sofia-bulgarie-d.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
      /* smartphone en mode portait */
      background-image: url("../../../assets/img/clinic/bulgaria/H1-clinique-dentaire-sofia-bulgarie-m.jpg");
    }
  }
</style>
